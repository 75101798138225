.bill-preview {
    display: flex;
    max-width: 1100px;
    height: 100%;
    flex-direction: column;
    margin: auto;
    color: rgb(36 36 36);
    text-align: left;

    h1 {
        color: rgb(45 83 130);
        font-size: 24px;
        line-height: 30px;
    }

    h2 {
        font-size: 16px;
        line-height: 24px;
    }

    a {
        color: rgb(108 166 148);
    }

    a:hover {
        color: rgb(0 124 222);
    }

    .actions {
        margin-bottom: 16px;
    }

    .content {
        overflow: auto;
        width: 100%;
        height: 100%;
        text-align: left;
    }
}

.bill-document-preview {
    margin-top: 40px;
}