.node {
    border: 1px solid #D9D9D9;
    border-radius: 0.25rem;
    margin: 5px;
    background-color: white;

    .menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 3px;
        border-bottom: 1px solid #D9D9D9;
        background-color: #FAFAFA;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        gap: 5px;
    }

    .text {
        display: inline-block;
        border: 1px solid transparent;
        color: #242424;
        font-family: "Droid Serif", serif;
        font-size: 16px;
        line-height: 24px;
    }

    &.inline {
        display: inline-block;
    }

    &.editable {
        > .children {
            padding-top: 10px;
            padding-bottom: 10px;
            margin-left: 20px;
        }
    }

    &.editable.edit-menu {
        display: block;
    } 

    .name > .text {
        font-style: italic;
    }

    .bold > .text {
        font-weight: bold;
    }

    .text:hover {
        background-color: aquamarine;
    }

    &.editable > .text {
        padding: 5px;
    }

    /* .text::after {
        content: " ";
    } */

    .node-type {
        margin-right: 10px;
        margin-left: 10px;
    }
}

.node:not(.editable) {
    border: 1px solid transparent;

    &.art > .children > .node.title::before {
        margin-right: 6px;
        content: url('../Editor/images/marker-art.png');
    }
    
    &.subart > .children::before {
        margin-right: 6px;
        content: url('../Editor//images/marker-subart.png');
    }

    &.inline {
        display: inline;

        > .text {
            display: inline;
        }
    }
}

.numart .title > .text {
    margin-left: 1.04em;
}

.highlighted .title > .text {
    background-color: rgb(91 167 234);
}

.highlighted .name > .text {
    background-color: rgb(239 186 11);
}

.node > .children > .chapter > .text {
    margin-top: 1.5em;
}

.chapter > .children > .title > .text,
.art > .children > .title > .text {
    font-weight: bold;
}

.chapter > .children > .name > .text {
    font-style: normal;
    font-weight: bold;
}

.add-button {
    width: calc(100% - 10px);
    margin: 5px;
}