.bill-document-explorer {
    width: 250px;
    height: 100%;
    border: 1px solid #d9d9d9;
    background-color: #fff;

    .bill-header {
        height: 40px;
        padding: 0 1rem;
        border-bottom: 1px solid #e0e0e0;
        background-color: #f5f5f5;

        .bill-title {
            font-size: 1.2rem;
            font-weight: 500;
            line-height: 40px;
        }
    }

    .ant-collapse {
        display: flex;
        height: calc(100% - 40px);
        flex-direction: column;
    }

    .ant-collapse-item {
        overflow: hidden;
        flex: 1 1 40px;
    }

    .ant-collapse-item:nth-of-type(1) {
        flex: 0 1 auto;
    }

    .ant-collapse-content {
        overflow: hidden;
        height: calc(100% - 38px);
    }

    .ant-collapse-content-box {
        overflow: hidden;
        height: 100%;
        padding: 0 !important;
    }

    .explorer-list {
        height: 100%;
        overflow-y: hidden;

        &.bill-documents {
            overflow-y: scroll;
        }
    }
    
    .list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        cursor: pointer;
        font-size: 12px;
        gap: 10px;
        line-height: 15px;

        &:hover {
            background-color: rgb(230 247 255);
        }

        &.selected {
            background-color: rgb(15 95 133);
        }
    }
    
    .item-infos {
        flex-grow: 1;
    }
    
    .item-title {

    }
    
    .item-identifier {
    
    }
    
    .item-actions {
    
    }
    
    .item-action {
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;

        &.delete:hover {
            background-color: #f6c665;
        }

        &.add:hover {
            background-color: #8ecb7e;
        }
    }
}

