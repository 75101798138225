.text-area {
    display: block;
    width: 100%;
    padding: 5px;

    &[data-placeholder]:empty::before{
        color: #888;
        content: attr(data-placeholder);
        font-style: italic;
      }
}