.hover-toolbar {
  padding: 8px;
  position: absolute;
  z-index: 1;
  top: -10000px;
  left: -10000px;
  margin-top: -6px;
  opacity: 0;
  background-color: rgb(34, 34, 34);
  border-radius: 4px;
  transition: opacity 0.75s;

  &>* {
    display: inline-block;
    color: rgb(170, 170, 170);
    border-color: transparent;
    background-color: transparent;
    width: 30px;

    &.active {
      color: white;
    }
  }

  &>*+* {
    margin-left: 15px;
  }
}