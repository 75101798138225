.editor {
    overflow: hidden auto;
    width: 100%;
    height: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    color: #242424;
    font-family: "Droid Serif", serif;
    font-size: 16px;
    line-height: 1.5em;

    .ant-collapse-header-text {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.2em;
    }
}


.list .list {
    padding-left: 0;
}

.list-item {
    list-style-type: none;
}

.list-item.art > .list-item-text > span > .title::before {
    margin-right: 6px;
    content: url('./images/marker-art.png');
}

.list-item.subart::before {
    margin-right: 6px;
    content: url('./images/marker-subart.png');
}

.numart .title {
    margin-left: 1.04em;
}

.list-item.chapter {
    margin-top: 1.5em;
}

.highlighted .title {
    background-color: rgb(91 167 234);
}

.chapter > .list-item-text > span > .title,
.art > .list-item-text > span > .title {
    font-weight: bold;
}

.name {
    font-style: italic;
}

.bold {
    font-weight: bold;
}

.highlighted .name {
    background-color: rgb(239 186 11);
}

.chapter > .list-item-text > span > .name {
    font-style: normal;
    font-weight: bold;
}

span.odd {
    background-color: #999;
}

span.even {
    background-color: #666;
}