body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.bill-document-explorer {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  width: 250px;
  height: 100%;
}

.bill-document-explorer .bill-header {
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
  height: 40px;
  padding: 0 1rem;
}

.bill-document-explorer .bill-header .bill-title {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 40px;
}

.bill-document-explorer .ant-collapse {
  flex-direction: column;
  height: calc(100% - 40px);
  display: flex;
}

.bill-document-explorer .ant-collapse-item {
  flex: 40px;
  overflow: hidden;
}

.bill-document-explorer .ant-collapse-item:first-of-type {
  flex: 0 auto;
}

.bill-document-explorer .ant-collapse-content {
  height: calc(100% - 38px);
  overflow: hidden;
}

.bill-document-explorer .ant-collapse-content-box {
  height: 100%;
  overflow: hidden;
  padding: 0 !important;
}

.bill-document-explorer .explorer-list {
  height: 100%;
  overflow-y: hidden;
}

.bill-document-explorer .explorer-list.bill-documents {
  overflow-y: scroll;
}

.bill-document-explorer .list-item {
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 10px;
  font-size: 12px;
  line-height: 15px;
  display: flex;
}

.bill-document-explorer .list-item:hover {
  background-color: #e6f7ff;
}

.bill-document-explorer .list-item.selected {
  background-color: #0f5f85;
}

.bill-document-explorer .item-infos {
  flex-grow: 1;
}

.bill-document-explorer .item-action {
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
}

.bill-document-explorer .item-action.delete:hover {
  background-color: #f6c665;
}

.bill-document-explorer .item-action.add:hover {
  background-color: #8ecb7e;
}

.editor {
  color: #242424;
  border: 1px solid #ccc;
  width: 100%;
  height: 100%;
  padding: 10px;
  font-family: Droid Serif, serif;
  font-size: 16px;
  line-height: 1.5em;
  overflow: hidden auto;
}

.editor .ant-collapse-header-text {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2em;
}

.list .list {
  padding-left: 0;
}

.list-item {
  list-style-type: none;
}

.list-item.art > .list-item-text > span > .title:before {
  content: url("marker-art.ee11f25c.png");
  margin-right: 6px;
}

.list-item.subart:before {
  content: url("marker-subart.25109253.png");
  margin-right: 6px;
}

.numart .title {
  margin-left: 1.04em;
}

.list-item.chapter {
  margin-top: 1.5em;
}

.highlighted .title {
  background-color: #5ba7ea;
}

.chapter > .list-item-text > span > .title, .art > .list-item-text > span > .title {
  font-weight: bold;
}

.name {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.highlighted .name {
  background-color: #efba0b;
}

.chapter > .list-item-text > span > .name {
  font-style: normal;
  font-weight: bold;
}

span.odd {
  background-color: #999;
}

span.even {
  background-color: #666;
}

.hover-toolbar {
  z-index: 1;
  opacity: 0;
  background-color: #222;
  border-radius: 4px;
  margin-top: -6px;
  padding: 8px;
  transition: opacity .75s;
  position: absolute;
  top: -10000px;
  left: -10000px;
}

.hover-toolbar > * {
  color: #aaa;
  background-color: #0000;
  border-color: #0000;
  width: 30px;
  display: inline-block;
}

.hover-toolbar > .active {
  color: #fff;
}

.hover-toolbar > * + * {
  margin-left: 15px;
}

.modal-overlay {
  backdrop-filter: blur(6px);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.animation-start {
  opacity: 0;
  pointer-events: none;
  transition: all .3s ease-in-out;
  transform: scale(.4);
}

.modal {
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: calc(100vh - 100px);
  display: flex;
  overflow-y: auto;
  box-shadow: 0 3px 6px #00000029;
}

.modal-enter-done {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1);
}

.modal-exit {
  opacity: 0;
  transform: scale(.4);
}

.b5OQlG_toolbar {
  opacity: 0;
  background-color: #222;
  border-radius: 4px;
  align-items: center;
  transition: opacity .5s ease-in-out, top .25s ease-in-out, height .25s ease-in-out;
  display: flex;
  position: absolute;
}

.b5OQlG_toolbar > * {
  color: #aaa;
  background-color: #0000;
  border-color: #0000;
  width: 30px;
  display: inline-block;
}

.b5OQlG_toolbar > .b5OQlG_active {
  color: #fff;
}

.b5OQlG_toolbar > * + * {
  margin-left: 15px;
}

.bill-preview {
  color: #242424;
  text-align: left;
  flex-direction: column;
  max-width: 1100px;
  height: 100%;
  margin: auto;
  display: flex;
}

.bill-preview h1 {
  color: #2d5382;
  font-size: 24px;
  line-height: 30px;
}

.bill-preview h2 {
  font-size: 16px;
  line-height: 24px;
}

.bill-preview a {
  color: #6ca694;
}

.bill-preview a:hover {
  color: #007cde;
}

.bill-preview .actions {
  margin-bottom: 16px;
}

.bill-preview .content {
  text-align: left;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.bill-document-preview {
  margin-top: 40px;
}

.node {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: .25rem;
  margin: 5px;
}

.node .menu {
  background-color: #fafafa;
  border-bottom: 1px solid #d9d9d9;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 3px;
  display: flex;
}

.node .text {
  color: #242424;
  border: 1px solid #0000;
  font-family: Droid Serif, serif;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
}

.node.inline {
  display: inline-block;
}

.node.editable > .children {
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.node.editable.edit-menu {
  display: block;
}

.node .name > .text {
  font-style: italic;
}

.node .bold > .text {
  font-weight: bold;
}

.node .text:hover {
  background-color: #7fffd4;
}

.node.editable > .text {
  padding: 5px;
}

.node .node-type {
  margin-left: 10px;
  margin-right: 10px;
}

.node:not(.editable) {
  border: 1px solid #0000;
}

.node:not(.editable).art > .children > .node.title:before {
  content: url("marker-art.ee11f25c.png");
  margin-right: 6px;
}

.node:not(.editable).subart > .children:before {
  content: url("marker-subart.25109253.png");
  margin-right: 6px;
}

.node:not(.editable).inline, .node:not(.editable).inline > .text {
  display: inline;
}

.numart .title > .text {
  margin-left: 1.04em;
}

.highlighted .title > .text {
  background-color: #5ba7ea;
}

.highlighted .name > .text {
  background-color: #efba0b;
}

.node > .children > .chapter > .text {
  margin-top: 1.5em;
}

.chapter > .children > .title > .text, .art > .children > .title > .text {
  font-weight: bold;
}

.chapter > .children > .name > .text {
  font-style: normal;
  font-weight: bold;
}

.add-button {
  width: calc(100% - 10px);
  margin: 5px;
}

.text-area {
  width: 100%;
  padding: 5px;
  display: block;
}

.text-area[data-placeholder]:empty:before {
  color: #888;
  content: attr(data-placeholder);
  font-style: italic;
}

.Mqx4nW_editor {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.Mqx4nW_actions {
  gap: 10px;
  padding: 5px 20px 10px 5px;
  display: flex;
}

.Mqx4nW_content {
  overflow: auto;
}
/*# sourceMappingURL=index.95734510.css.map */
